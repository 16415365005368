import React from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getMintControllerAddress } from '../../constants/addressBook'
import mintControllerABI from '../../abis/mintControllerABI'

export const WithdrawMintController: React.FC = () => {

  return (
    <div>
      <label>Withdraw
      <button onClick={async () => {
        const network = await getNetwork()
        console.log(network.chain?.name)
        const address = getMintControllerAddress(network.chain?.name)
        writeContract({
          address: address,
          abi: mintControllerABI,
          functionName: "withdraw",
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}