import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getArticleNFTAddress } from '../../constants/addressBook'
import articleNFTABI from '../../abis/articleNFTABI'

export const CreateArticleNFTForm: React.FC = () => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [uri, setUri] = useState("ipfs://");

  return (
    <div>
      <label>Create Article NFT
        <form>
          <label>Mint Start Time (Unix)
            <input
              type="text" 
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </label>

          <label>Mint End Time (Unix)
            <input
              type="text" 
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </label>

          <label>URI
            <input
              type="text" 
              value={uri}
              onChange={(e) => setUri(e.target.value)}
            />
          </label>
        </form>
        <button onClick={async () => {
          const network = await getNetwork()
          console.log(network.chain?.name)
          const articleNFTAddress = getArticleNFTAddress(network.chain?.name)
          writeContract({
            address: articleNFTAddress,
            abi: articleNFTABI,
            functionName: "createNewArticle",
            args: [BigInt(startTime), BigInt(endTime), uri]
          })
        }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}