import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getAuctionAddress } from '../../constants/addressBook'
import AuctionABI from '../../abis/auctionABI'

export const CreateTokenAndStartAuction
: React.FC = () => {
  const [Uri, setUri] = useState("ipfs://");
  const [deadline, setDeadline] = useState("");

  return (
    <div>
      <label>Create Token and Start Auction
      <form>
        <label>uri
          <input
            type="text" 
            value={Uri}
            onChange={(e) => setUri(e.target.value)}
          />
        </label>
      </form>

      <form>
        <label>deadline
          <input
            type="text" 
            value={deadline}
            onChange={(e) => setDeadline(e.target.value)}
          />
        </label>
      </form>
      
      <button onClick={async () => {
        const network = await getNetwork()
        const AuctionAddress = getAuctionAddress(network.chain?.name)
        writeContract({
          address: AuctionAddress,
          abi: AuctionABI,
          functionName: "createTokenAndStartAuction",
          args: [Uri, deadline]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}