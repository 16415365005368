import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getFirstEditionNFTAddress } from '../../constants/addressBook'
import firstEditionNFTABI from '../../abis/firstEditionNFTABI'

export const CreateFirstEditionArticleNFT: React.FC = () => {
  const [recipeint, setRecipient] = useState("");
  const [uri, setUri] = useState("ipfs://");

  return (
    <div>
      <label>Create First Edition Article NFT
        <form>
          <label>Recipient
            <input
              type="text" 
              value={recipeint}
              onChange={(e) => setRecipient(e.target.value)}
            />
          </label>

          <label>URI
            <input
              type="text" 
              value={uri}
              onChange={(e) => setUri(e.target.value)}
            />
          </label>
        </form>
        <button onClick={async () => {
          const network = await getNetwork()
          console.log(network.chain?.name)
          const firstEditionNFTAddress = getFirstEditionNFTAddress(network.chain?.name)
          writeContract({
            address: firstEditionNFTAddress,
            abi: firstEditionNFTABI,
            functionName: "createNewArticle",
            args: [recipeint, uri]
          })
        }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}