
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import {
  base,
  mainnet,
  sepolia,
  hardhat,
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";

import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { ConnectButton } from "@rainbow-me/rainbowkit";

import React from "react";
import ControlPanel from "./pages/controlPanel";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, base, sepolia, hardhat],
  [
    alchemyProvider({ apiKey: "Bs8E8y22L3R-hY3kT6HgoPVpWK6PyIs0" }),
    publicProvider(),
  ]
  // { batch: { multicall: true } }, TODO: evaluate if we need multicalls when app is done
);

const { connectors } = getDefaultWallets({
  appName: "The_Daily_Pepe",
  projectId: "76188143aa891f2eedd2112c16da7d25",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  return (
    <main>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains}>
          <ConnectButton />
          <ControlPanel/>
        </RainbowKitProvider>
      </WagmiConfig>
    </main>
  );
};

export default App;
