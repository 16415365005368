import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getMintControllerAddress } from '../../constants/addressBook'
import mintControllerABI from '../../abis/mintControllerABI'

export const SetMintPrice: React.FC = () => {
  const [articleId, setArticleId] = useState("")
  const [newMintPrice, setnewMintPrice] = useState("");

  return (
    <div>
      <label>Set Mint Price
      <form>
        <label>Article Id
          <input
            type="text" 
            value={articleId}
            onChange={(e) => setArticleId(e.target.value)}
          />
        </label>
      </form>
      <form>
        <label>Mint Price
          <input
            type="text" 
            value={newMintPrice}
            onChange={(e) => setnewMintPrice(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        const address = getMintControllerAddress(network.chain?.name)
        writeContract({
          address: address,
          abi: mintControllerABI,
          functionName: "setMintPrice",
          args: [BigInt(articleId), BigInt(newMintPrice)]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}