import { parseEther } from "viem";
import React from "react"
import { useState } from "react";

export const ParseEther: React.FC = () => {
  const [etherAmount, setEtherAmount] = useState("1.0");
  const [weiAmount, setWeiAmount] = useState("1000000000000000000")

  return (
    <div>
      <label>Convert Ether to wei
      <form>
        <label>Ether amount
          <input
            type="text" 
            value={etherAmount}
            onChange={(e) => setEtherAmount(e.target.value)}
          />
        </label>
      </form>
      <button onClick={() => {
        setWeiAmount(parseEther(etherAmount).toString())
      }}>Convert</button>
      </label>
      <br/>
      <br/>
      in Wei
      <div>{weiAmount.toString()}</div>
    </div>
  )
}