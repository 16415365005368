import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getMintControllerAddress } from '../../constants/addressBook'
import mintControllerABI from '../../abis/mintControllerABI'

export const SetAffiliateShareDivisor: React.FC = () => {
  const [newDivisor, setnewDivisor] = useState("");

  return (
    <div>
      <label>Set Affiliate Share Devisor
      <form>
        <label>New Divisor
          <input
            type="text" 
            value={newDivisor}
            onChange={(e) => setnewDivisor(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        const address = getMintControllerAddress(network.chain?.name)
        writeContract({
          address: address,
          abi: mintControllerABI,
          functionName: "setAffiliateShareDivisor",
          args: [BigInt(newDivisor)]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}