import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getArticleNFTAddress } from '../../constants/addressBook'
import articleNFTABI from '../../abis/articleNFTABI'

export const AdminMintArticleNFTForm: React.FC = () => {
  const [destinationAccount, setdestinationAccount] = useState("");
  const [articleId, setArticleId] = useState("");
  const [amount, setAmount] = useState("");

  return (
    <div>
      <label>Admin mint article NFT without paying fees
      <form>
        <label>Destination account
          <input
            type="text" 
            value={destinationAccount}
            onChange={(e) => setdestinationAccount(e.target.value)}
          />
        </label>

        <label>Article Id
          <input
            type="text" 
            value={articleId}
            onChange={(e) => setArticleId(e.target.value)}
          />
        </label>

        <label>Amount
          <input
            type="text" 
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        console.log(network.chain?.name)
        const articleNFTAddress = getArticleNFTAddress(network.chain?.name)
        writeContract({
          address: articleNFTAddress,
          abi: articleNFTABI,
          functionName: "mint",
          args: [destinationAccount, BigInt(articleId), amount]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}