import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getAuctionAddress } from '../../constants/addressBook'
import AuctionABI from '../../abis/auctionABI'

export const SetBenefactorAuction
: React.FC = () => {
  const [newBenefactor, setNewBenefactor] = useState("");

  return (
    <div>
      <label>Change Benefactor
      <form>
        <label>New Admin
          <input
            type="text" 
            value={newBenefactor}
            onChange={(e) => setNewBenefactor(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        const AuctionAddress = getAuctionAddress(network.chain?.name)
        writeContract({
          address: AuctionAddress,
          abi: AuctionABI,
          functionName: "setBenefactor",
          args: [newBenefactor]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}