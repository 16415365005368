import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getArticleNFTAddress } from '../../constants/addressBook'
import articleNFTABI from '../../abis/articleNFTABI'

export const SetURIArticleNFT: React.FC = () => {
  const [articleId, setArticleId] = useState("");
  const [Uri, setUri] = useState("ipfs://");

  return (
    <div>
      <label>Set a new URI for an article
      <form>
        <label>Article Id
          <input
            type="text" 
            value={articleId}
            onChange={(e) => setArticleId(e.target.value)}
          />
        </label>

        <label>Uri
          <input
            type="text" 
            value={Uri}
            onChange={(e) => setUri(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        console.log(network.chain?.name)
        const articleNFTAddress = getArticleNFTAddress(network.chain?.name)
        writeContract({
          address: articleNFTAddress,
          abi: articleNFTABI,
          functionName: "setURI",
          args: [BigInt(articleId), Uri]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}