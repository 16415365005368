import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getMintControllerAddress } from '../../constants/addressBook'
import mintControllerABI from '../../abis/mintControllerABI'

export const SetBenefactorMintController: React.FC = () => {
  const [newBenefactor, setnewBenefactor] = useState("");

  return (
    <div>
      <label>Set Benefactor
      <form>
        <label>New Benefactor
          <input
            type="text" 
            value={newBenefactor}
            onChange={(e) => setnewBenefactor(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        const address = getMintControllerAddress(network.chain?.name)
        writeContract({
          address: address,
          abi: mintControllerABI,
          functionName: "setBenefactor",
          args: [newBenefactor]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}