import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getAuctionAddress } from '../../constants/addressBook'
import AuctionABI from '../../abis/auctionABI'
import { adminRole } from "../../constants/roles";

export const RemoveAdminAuction
: React.FC = () => {
  const [newAdmin, setnewAdmin] = useState("");

  return (
    <div>
      <label>Remove an Admin Permission
      <form>
        <label>New Admin
          <input
            type="text" 
            value={newAdmin}
            onChange={(e) => setnewAdmin(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        const AuctionAddress = getAuctionAddress(network.chain?.name)
        writeContract({
          address: AuctionAddress,
          abi: AuctionABI,
          functionName: "revokeRole",
          args: [adminRole, newAdmin]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}