import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getArticleNFTAddress } from '../../constants/addressBook'
import articleNFTABI from '../../abis/articleNFTABI'
import { minterRole } from "../../constants/roles";

export const RemoveMinterArticleNFT: React.FC = () => {
  const [newMinter, setnewMinter] = useState("");

  return (
    <div>
      <label>Revoke a Minter permission
      <form>
        <label>New Minter
          <input
            type="text" 
            value={newMinter}
            onChange={(e) => setnewMinter(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        console.log(network.chain?.name)
        const articleNFTAddress = getArticleNFTAddress(network.chain?.name)
        writeContract({
          address: articleNFTAddress,
          abi: articleNFTABI,
          functionName: "revokeRole",
          args: [minterRole, newMinter]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}