import React from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getAuctionAddress } from '../../constants/addressBook'
import AuctionABI from '../../abis/auctionABI'

export const PayoutAllAuction
: React.FC = () => {

  return (
    <div>
      <label>Payout All
      <button onClick={async () => {
        const network = await getNetwork()
        const AuctionAddress = getAuctionAddress(network.chain?.name)
        writeContract({
          address: AuctionAddress,
          abi: AuctionABI,
          functionName: "payoutAll",
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}