import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getMintControllerAddress } from '../../constants/addressBook'
import MintControllerABI from '../../abis/mintControllerABI'
import { adminRole } from "../../constants/roles";

export const AddAdminMintController
: React.FC = () => {
  const [newAdmin, setnewAdmin] = useState("");

  return (
    <div>
      <label>Add a new Admin Permission
      <form>
        <label>New Admin
          <input
            type="text" 
            value={newAdmin}
            onChange={(e) => setnewAdmin(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        const MintControllerAddress = getMintControllerAddress(network.chain?.name)
        writeContract({
          address: MintControllerAddress,
          abi: MintControllerABI,
          functionName: "grantRole",
          args: [adminRole, newAdmin]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}